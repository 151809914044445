<template>
  <div
    id="disable-right-click"
    class="ct-detail page-content-detail"
    v-if="
      detailStore && checkShow && detailStore[0] && !detailStore[0].duplicate
    "
  >
    <div
      class="content-cart"
      v-if="
        dataContent && dataContent.sale_basic && dataContent.sale_basic.column
      "
    >
      <div class="title-price">
        ¥{{
          dataContent.sale_basic.column[0].fee
            ? parseInt(dataContent.sale_basic.column[0].product_price) +
              parseInt(dataContent.sale_basic.column[0].fee)
            : parseInt(dataContent.sale_basic.column[0].product_price)
        }}
        <!-- <small style="color: black; font-size: 60%">（税込）</small> -->
      </div>
      <div class="title-text-price">
        {{ dataContent.sale_basic.column[0].textPrice }}
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-success"
          block
          v-on:click.prevent="addCart(dataContent.id)"
          >カートに入れる</b-button
        >
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="buyNow(dataContent.id)"
        >
          今すぐ購入する
        </b-button>
      </div>
    </div>
    <div
      class="container py-0 pb-4"
      v-if="dataContent && Object.keys(dataContent).length > 0"
    >
      <div class="dc-header mb-4">
        <b-container fluid class="d-flex align-items-center font-title">
          <router-link
            :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
          >
            <div>ショップ</div>
          </router-link>
          <b-icon icon="chevronRight" class="mx-3"></b-icon>
          <router-link
            :to="{
              name: $route.params.shopId ? 'store' : 'store domain',
              query: { keyword: keywordFilter },
            }"
          >
            <div>絞り込み検索結果</div>
          </router-link>
          <b-icon icon="chevronRight" class="mx-3"></b-icon>
          <router-link :to="{ name: '' }">
            <div>タイトルタイトル</div>
          </router-link>
        </b-container>
      </div>
      <div v-if="dataContent.sale_other.column" style="margin-bottom: 40px">
        <div
          v-for="(item, indexList) in dataContent.sale_other.column"
          :key="indexList"
        >
          <div
            class="title-content-wrapper"
            v-bind:class="{
              'is-header': item.text == '商品画像とタイトル表示',
              mt40: indexList == 0 && item.text != '商品画像とタイトル表示',
            }"
          >
            <div
              v-if="
                dataContent.sale_basic.column &&
                dataContent.sale_basic.column.length > 0 &&
                indexList === 0 &&
                item.text != '商品画像とタイトル表示'
              "
              class="version-pc"
            >
              <div
                class="price-list"
                v-for="(value, index) in dataContent.sale_basic.column"
                :key="index"
              >
                <div class="title-price-list">価格一覧</div>
                <div
                  class="title-many-price"
                  v-if="dataContent.sale_basic.column.length > 1"
                >
                  ※複数プランがあります
                </div>
              </div>
            </div>
            <div
              class="title-content d-flex version-pc"
              :class="item.text == '商品画像とタイトル表示' ? 'w-100' : 'w-50'"
              v-if="item.text == '商品画像とタイトル表示'"
            >
              <div class="image-content">
                <img
                  :src="
                    dataContent.image_url
                      ? `${urlBackend}/storage/${dataContent.image_url}`
                      : image_url_default
                  "
                />
              </div>
              <div class="description-content">
                <div class="title">{{ dataContent.content_title }}</div>
                <div class="d-flex author align-items-center">
                  <div class="avatar-author">
                    <img
                      :src="
                        dataContent.avatar
                          ? `${urlBackend}/${dataContent.avatar}`
                          : no_image
                      "
                    />
                  </div>
                  <div class="">
                    {{ dataContent.last_name + " " + dataContent.first_name }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="title-content d-flex version-sp"
              :class="item.text == '商品画像とタイトル表示' ? 'w-100' : 'w-50'"
              v-if="item.text == '商品画像とタイトル表示'"
              style="background: #fff; color: black"
            >
              <div class="image-content">
                <img
                  v-if="dataContent.image_url"
                  :src="
                    dataContent.image_url
                      ? `${urlBackend}/storage/${dataContent.image_url}`
                      : image_url_default
                  "
                />
                <span v-else class="title-no-img">{{
                  dataContent.content_title
                }}</span>
              </div>
              <div class="description-content">
                <div class="title">{{ dataContent.content_title }}</div>
              </div>
            </div>
            <div class="container-contents">
              <div
                v-if="
                  dataContent.sale_basic.column &&
                  dataContent.sale_basic.column.length > 0 &&
                  indexList === 0 &&
                  item.text == '商品画像とタイトル表示'
                "
                class="version-pc"
              >
                <div
                  class="price-list"
                  v-for="(value, index) in dataContent.sale_basic.column"
                  :key="index"
                >
                  <div class="title-price-list">価格一覧</div>
                  <div
                    class="title-many-price"
                    v-if="dataContent.sale_basic.column.length > 1"
                  >
                    ※複数プランがあります
                  </div>
                </div>
              </div>
              <div class="author-info" v-if="item.text == '制作者情報'">
                <div class="label-info">
                  <span class="titleBorder"></span>
                  <span class="title">著者について</span>
                </div>
                <div class="d-flex author align-items-center">
                  <div class="avatar-author">
                    <img
                      :src="
                        dataContent.avatar
                          ? `${urlBackend}/${dataContent.avatar}`
                          : no_image
                      "
                    />
                  </div>
                  <div class="">
                    {{ dataContent.last_name + " " + dataContent.first_name }}
                  </div>
                </div>
              </div>
              <div
                class="catalogue-info"
                v-if="
                  item.text == '目次表示' && dataContent.catalogues.length > 0
                "
              >
                <div class="label-info">
                  <span class="titleBorder"></span>
                  <span class="title">収録目次一覧</span>
                </div>
                <div class="catalogue" style="padding-bottom: 20px">
                  <div
                    class="catalogue-list"
                    style="
                      margin-left: 10px;
                      border-top: 1px solid rgb(230, 230, 230);
                      border-bottom: 1px solid rgb(230, 230, 230);
                    "
                  >
                    <div
                      class="contents-list-item position-relative"
                      v-for="(item, index) in dataContent.catalogues"
                      :key="index"
                    >
                      <div class="content-catalogue" style="margin-left: 7px">
                        <div style="width: 70px"></div>
                        <div>
                          <div
                            v-if="item.title"
                            class="customTitleChapter"
                            v-bind:class="{ noBorderTop: index === 0 }"
                            style="margin-left: 70px"
                          >
                            <span>{{ item.title }}</span>
                          </div>
                          <div class="hoverTittlePage" v-if="item.page">
                            <div
                              class="textName customTitlePage d-flex"
                              v-for="(ele, index) in item.page"
                              :key="index"
                            >
                              <div class="pageBorder">
                                <div v-if="ele.is_read" class="icon-check">
                                  <b-icon icon="check"></b-icon>
                                </div>
                                <div v-else class="icon-check">
                                  <img
                                    class="icon-no-read"
                                    :src="imgNoRead"
                                    alt=""
                                  />
                                </div>
                                <div style="padding-left: 60px">
                                  {{ ele.page_title }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="hoverTittlePage" v-else>
                            <div class="textName customTitlePage d-flex">
                              <div class="pageBorder">
                                <div v-if="item.is_read" class="icon-check">
                                  <b-icon icon="check"></b-icon>
                                </div>
                                <div class="icon-check" v-else>
                                  <img
                                    class="icon-no-read"
                                    :src="imgNoRead"
                                    alt=""
                                  />
                                </div>
                                <div style="">
                                  {{ item.page_title }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :style="
                          index === 0
                            ? { background: '#fff' }
                            : { background: '#d1d1d1' }
                        "
                        class="line-catalogue-above"
                      ></div>
                      <div
                        :style="
                          index === dataContent.catalogues.length - 1
                            ? { background: '#fff' }
                            : { background: '#d1d1d1' }
                        "
                        class="line-catalogue-below"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
              class="w-50"
              v-if="dataContent.sale_other.url_video && item.value == 3"
            >
              <div class="label-info">
                <span class="titleBorder"></span>
                <span class="title">紹介ビデオ</span>
              </div>
              <div>
                <iframe
                  width="420"
                  height="345"
                  src="https://www.youtube.com/embed/SXTQxxSaBFU"
                ></iframe>
              </div>
            </div> -->
              <div class="accordion-page" v-if="item.text == '自由記入'">
                <div class="label-info">
                  <span class="titleBorder"></span>
                  <span class="title">{{ item.title }}</span>
                </div>
                <!-- <div class="pl-3" v-html="item.content"></div> -->
                <div class="pl-3 pr-3">
                  <b-card-body
                    class="px-0"
                    v-if="(item.text = '自由記入')"
                    :style="{
                      lineHeight: dataContent.line_height
                        ? dataContent.line_height + 'px'
                        : 'unset',
                    }"
                  >
                    <div
                      class="w-100"
                      v-for="(itemBody, indexBody) in item.content.blocks"
                      :key="indexBody"
                      :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
                    >
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'paragraph'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :style="{
                          lineHeight:
                            itemBody.data.lineHeight &&
                            itemBody.data.lineHeight !== null
                              ? itemBody.data.lineHeight
                              : '',
                        }"
                      >
                        <div
                          v-if="itemBody.data.text"
                          v-html="itemBody.data.text"
                        ></div>
                        <div
                          v-else
                          v-html="itemBody.data.text"
                          style="height: 1.6em"
                        ></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'tableOfContents'"
                      >
                        <h3>目次</h3>
                        <ul class="list-unstyled pl-4 listTableOfContent">
                          <li
                            v-for="(itemTOC, index) in itemBody.data.items"
                            :key="index"
                            class="pl-3"
                          >
                            <a
                              style="cursor: pointer"
                              v-scroll-to="{
                                el: '#' + itemTOC.href,
                                offset: -20,
                              }"
                              v-html="itemTOC.text"
                            ></a>
                          </li>
                        </ul>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'header'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <h1
                          v-if="itemBody.data.level === 1"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h1>
                        <h2
                          v-if="itemBody.data.level === 2"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h2>
                        <h3
                          v-if="itemBody.data.level === 3"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h3>
                        <h4
                          v-if="itemBody.data.level === 4"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h4>
                        <h5
                          v-if="itemBody.data.level === 5"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h5>
                        <h6
                          v-if="itemBody.data.level === 6"
                          class="mb-0"
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                          v-html="itemBody.data.text"
                        ></h6>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'list'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <ul class="w-100" style="list-style-type: decimal">
                          <li
                            v-for="(itemList, indexList) in itemBody.data.items"
                            :key="indexList"
                            class="mb-2"
                            v-html="itemList"
                          ></li>
                        </ul>
                      </div>
                      <div
                        class="item-data w-100"
                        v-if="itemBody.type === 'toggle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div
                          class="w-100 d-flex align-items-center toggle-block__selector"
                          :id="itemBody.id"
                          :class="
                            itemBody.data.status === 'open'
                              ? 'toggle-open'
                              : 'toggle-close'
                          "
                        >
                          <span
                            class="icon-toggle-panel mr-2"
                            @click="toggleBlock(itemBody)"
                          >
                            <b-icon
                              :icon="
                                itemBody.data.status === 'open'
                                  ? 'x-lg'
                                  : 'plus-lg'
                              "
                            ></b-icon>
                          </span>
                          {{ itemBody.data.text }}
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'code'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div v-html="itemBody.data.code"></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100 my-3"
                        v-if="itemBody.type === 'raw'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : ''
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div v-html="itemBody.data.html"></div>
                        <!-- <code>{{ itemBody.data.html }}</code> -->
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'table'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                v-for="(itemTblTh, indexTblTh) in itemBody.data
                                  .content[0]"
                                :key="indexTblTh"
                                class="border-bottom-0"
                              >
                                <span class="w-100" v-html="itemTblTh"></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(itemTblTr, indexTblTr) in itemBody.data
                                .contentTbody"
                              :key="indexTblTr"
                            >
                              <td
                                v-for="(itemTblTd, indexTblTd) in itemTblTr"
                                :key="indexTblTd"
                              >
                                <span class="w-100" v-html="itemTblTd"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'checklist'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div
                          class="custom-control custom-checkbox"
                          v-for="(itemCheckbox, indexCheckbox) in itemBody.data
                            .items"
                          :key="indexCheckbox"
                        >
                          <input
                            :id="'checkbox-' + itemBody.id + indexCheckbox"
                            type="checkbox"
                            :name="'checkbox-' + itemBody.id + indexCheckbox"
                            class="custom-control-input"
                            :checked="itemCheckbox.checked"
                          />
                          <label
                            :for="'checkbox-' + itemBody.id + indexCheckbox"
                            class="custom-control-label ml-1"
                          >
                            <span v-html="itemCheckbox.text"></span>
                          </label>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'warning'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div class="w-100 bg-warning p-3 text-white">
                          <div class="w-100 font-weight-bold">
                            {{ itemBody.data.title }}
                          </div>
                          <hr />
                          <div class="w-100">
                            {{ itemBody.data.message }}
                          </div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'quote'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <blockquote
                          class="otro-blockquote"
                          :class="
                            itemBody.data.alignment === 'left'
                              ? 'text-left'
                              : 'text-right'
                          "
                        >
                          <span>
                            {{ itemBody.data.caption }}
                          </span>
                          <span v-html="itemBody.data.text"></span>
                        </blockquote>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'headerStyle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div class="titleStyle" :class="itemBody.data.class">
                          <h1 class="dataInput">
                            {{ itemBody.data.text }}
                          </h1>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'boxStylle'"
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div class="boxStyle" :class="itemBody.data.class">
                          <span
                            class="box-title px-2 border-0"
                            v-if="itemBody.data.title"
                            >{{ itemBody.data.title }}</span
                          >
                          <div
                            class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                            v-html="itemBody.data.text"
                          ></div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'delimiter'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <div class="w-100 text-center ce-delimiter"></div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'image'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <a
                          v-if="replaceText(itemBody.data.linkImage)"
                          :href="itemBody.data.linkImage"
                        >
                          <img :src="itemBody.data.file.url" width="auto" />
                        </a>
                        <img
                          v-else
                          :src="itemBody.data.file.url"
                          alt=""
                          width="auto"
                        />
                        <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'attachesPDF'"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <!--<ViewPdf
                        v-bind:pageNumber="lastPagePDF"
                        v-bind:timeWatching="timeWatchingEmbed"
                        v-bind:idLast="idLastPDF"
                        v-bind:src="itemBody.data.file.url"
                        v-bind:idComponent="itemBody.id"
                      /> -->
                        <b-button
                          variant="primary"
                          class="btn-preview-fixed mr-0"
                          name="btn-view-PDF"
                          v-on:click.prevent="viewFilePDF(itemBody.data.file)"
                        >
                          {{ itemBody.data.title }}
                        </b-button>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        v-if="
                          itemBody.type === 'embed' &&
                          itemBody.data.service === 'youtube'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <YoutubePlayer
                          v-bind:idElemYoutube="itemBody.id"
                          v-bind:idLastYoutube="idLastEmbed"
                          v-bind:timeWatching="timeWatchingEmbed"
                          v-bind:src="itemBody.data.source"
                          v-bind:heightPlayer="itemBody.data.height"
                          v-bind:widthPlayer="itemBody.data.width"
                          v-bind:dataVideo="itemBody.data"
                        />
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        :class="
                          itemBody.idChild
                            ? checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : 'my-3'
                        "
                        v-if="
                          itemBody.type === 'embed' &&
                          itemBody.data.service === 'vimeo'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <VimeoPlayer
                          v-bind:idElemVimeo="itemBody.id"
                          v-bind:idLastVimeo="idLastEmbed"
                          v-bind:timeWatching="timeWatchingEmbed"
                          v-bind:src="itemBody.data.embed"
                          v-bind:heightPlayer="itemBody.data.height"
                          v-bind:widthPlayer="itemBody.data.width"
                          v-bind:dataVideo="itemBody.data"
                        />
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100"
                        v-if="itemBody.type === 'anyButton'"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        :id-child="itemBody.idChild"
                        :hidden="itemBody.showDisplay === true ? true : false"
                      >
                        <a
                          v-if="itemBody.data.type === null"
                          class="anyButton"
                          :class="itemBody.data.style"
                          @click="downloadFile($event, itemBody.data)"
                        >
                          {{ itemBody.data.text }}
                        </a>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'roundButton' ||
                              itemBody.data.style === 'socialGlossySmooth' ||
                              itemBody.data.style === 'socialSquare')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <a
                            v-if="itemBody.data.twitter !== null"
                            :href="itemBody.data.twitter"
                            class="btn-social-circle btn-social-circle--twitter"
                            ><i class="fab fa-twitter"></i>
                          </a>
                          <a
                            v-if="itemBody.data.facebook !== null"
                            :href="itemBody.data.facebook"
                            class="btn-social-circle btn-social-circle--facebook"
                            ><i class="fab fa-facebook-f"></i>
                          </a>
                          <a
                            v-if="itemBody.data.pocket !== null"
                            :href="itemBody.data.pocket"
                            class="btn-social-circle btn-social-circle--pocket"
                            ><i class="fab fa-get-pocket"></i>
                          </a>
                          <a
                            v-if="itemBody.data.feedly !== null"
                            :href="itemBody.data.feedly"
                            class="btn-social-circle btn-social-circle--feedly"
                            ><i class="fas fa-rss"></i
                          ></a>
                        </div>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'socialGiza' ||
                              itemBody.data.style === 'socialSmartPhone')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <div
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza'
                                : 'btn-social-phone'
                            "
                          >
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-twitter'
                                  : 'btn-social-phone-twitter ml-0 mr-3'
                              "
                              v-if="itemBody.data.twitter !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-twitter fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="3535"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-facebook'
                                  : 'btn-social-phone-facebook ml-0 mr-3'
                              "
                              v-if="itemBody.data.facebook !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-facebook-f fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="5467"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-pocket'
                                  : 'btn-social-phone-pocket ml-0 mr-3'
                              "
                              v-if="itemBody.data.pocket !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fab fa-get-pocket fa-stack-1x"></i>
                              </span>
                            </a>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialGiza'
                                  ? 'btn-social-giza-feedly'
                                  : 'btn-social-phone-feedly ml-0 mr-3'
                              "
                              v-if="itemBody.data.feedly !== null"
                            >
                              <span class="fa-stack">
                                <i
                                  class="fas fa-stack-2x"
                                  :class="
                                    itemBody.data.style === 'socialGiza'
                                      ? 'fa-certificate'
                                      : 'fa-tablet-alt'
                                  "
                                ></i>
                                <i class="fas fa-rss fa-stack-1x"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                        <div
                          class="w-100"
                          v-if="
                            itemBody.data.type !== null &&
                            (itemBody.data.style === 'socialFlat' ||
                              itemBody.data.style === 'socialIsometric')
                          "
                          :class="
                            itemBody.tunes && itemBody.idChild
                              ? itemBody.tunes.anyTuneName.alignment ===
                                'center'
                                ? 'toggle-block__item text-center'
                                : itemBody.tunes &&
                                  itemBody.tunes.anyTuneName.alignment ===
                                    'right'
                                ? 'toggle-block__item text-right'
                                : checkIndex(itemBody)
                                ? 'toggle-block__item text-left toggle-item-last'
                                : 'toggle-block__item text-left'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment ===
                                  'center'
                              ? 'text-center my-3'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'text-right my-3'
                              : ' text-left my-3'
                          "
                        >
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                                "
                              >
                                <i class="fab fa-twitter"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                                "
                              >
                                <i class="fab fa-facebook"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                                "
                              >
                                <i class="fab fa-get-pocket"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                          <div>
                            <a
                              href="1"
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat'
                                  : 'btn-social-isometric'
                              "
                            >
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                                    : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                                "
                              >
                                <i class="fas fa-rss"></i>
                              </span>
                              <span
                                :class="
                                  itemBody.data.style === 'socialFlat'
                                    ? 'btn-social-flat-text'
                                    : 'btn-social-isometric-text'
                                "
                                >TWEET</span
                              >
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="itemBody.id"
                        class="item-data w-100 my-3"
                        v-if="itemBody.type === 'attachesFile'"
                      >
                        <div class="w-100 d-flex box-file-upload">
                          <div
                            class="iconFile"
                            :data-extension="itemBody.data.file.extension"
                            :style="{ color: itemBody.data.file.color }"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="40"
                            >
                              <path
                                d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                              />
                            </svg>
                          </div>
                          <div class="fileName">
                            <div class="w-100">
                              {{ itemBody.data.file.name }}
                            </div>
                            <div
                              class="text-muted w-100"
                              v-html="convertSize(itemBody.data.file.size)"
                            ></div>
                          </div>
                          <div class="iconDowload ml-auto">
                            <a
                              class="iconDowload"
                              v-bind:download="itemBody.data.file.url"
                              rel="nofollow noindex noreferrer"
                              @click="
                                downloadFileUpload($event, itemBody.data.file)
                              "
                            >
                              <i class="fas fa-cloud-download-alt"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- check -->
        <div class="title-content-wrapper is-header">
          <div class="title-content d-flex w-100 version-pc">
            <div class="image-content">
              <img
                :src="
                  dataContent.image_url
                    ? `${urlBackend}/storage/${dataContent.image_url}`
                    : image_url_default
                "
              />
            </div>
            <div class="description-content">
              <div class="title">{{ dataContent.content_title }}</div>
              <div class="d-flex author align-items-center">
                <div class="avatar-author">
                  <img
                    :src="
                      dataContent.avatar
                        ? `${urlBackend}/${dataContent.avatar}`
                        : no_image
                    "
                  />
                </div>
                <div class="author-name">
                  {{ dataContent.last_name + " " + dataContent.first_name }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="title-content d-flex w-100 version-sp"
            style="background: #fff; color: black"
          >
            <div class="image-content">
              <img
                v-if="dataContent.image_url"
                :src="
                  dataContent.image_url
                    ? `${urlBackend}/storage/${dataContent.image_url}`
                    : image_url_default
                "
              />
              <span v-else class="title-no-img">{{
                dataContent.content_title
              }}</span>
            </div>
            <div class="description-content">
              <div class="title">{{ dataContent.content_title }}</div>
            </div>
          </div>
        </div>
        <div class="container-contents">
          <div
            v-if="
              dataContent.sale_basic.column &&
              dataContent.sale_basic.column.length > 0
            "
            class="version-pc"
          >
            <div
              class="price-list"
              v-for="(value, index) in dataContent.sale_basic.column"
              :key="index"
            >
              <div class="title-price-list">価格一覧</div>
              <div
                class="title-many-price"
                v-if="dataContent.sale_basic.column.length > 1"
              >
                ※複数プランがあります
              </div>
              <div class="info-price">
                <div class="plan-name">{{ value.product_title }}</div>
                <div class="plan-price" v-if="value.textPrice">
                  {{ value.textPrice }}
                </div>
                <div class="plan-price" v-else>
                  ¥{{
                    value.fee
                      ? parseInt(value.product_price) + parseInt(value.fee)
                      : parseInt(value.product_price)
                  }}<small>（税込）</small>
                </div>
                <div
                  class="text-button"
                  v-if="value.textButton"
                  @click="buyNow(dataContent.id)"
                >
                  <button>{{ value.textButton }}</button>
                </div>
                <div class="add-cart btn" @click="addCart(dataContent.id)">
                  <button>カートに入れる</button>
                </div>
                <div class="text-below-button">{{ value.textBelowButton }}</div>
              </div>
            </div>
          </div>
          <div class="catalogue-info w-50">
            <div class="label-info">
              <span class="titleBorder"></span>
              <span class="title">収録目次一覧</span>
            </div>
            <div class="catalogue" style="padding-bottom: 20px">
              <div
                class="catalogue-list"
                style="
                  margin-left: 10px;
                  border-top: 1px solid rgb(230, 230, 230);
                  border-bottom: 1px solid rgb(230, 230, 230);
                "
              >
                <div
                  class="contents-list-item position-relative"
                  v-for="(item, index) in dataContent.catalogues"
                  :key="index"
                >
                  <div class="content-catalogue" style="margin-left: 7px">
                    <div style="width: 70px"></div>
                    <div>
                      <div
                        class="customTitleChapter"
                        v-bind:class="{ noBorderTop: index === 0 }"
                        style="margin-left: 70px"
                      >
                        <span v-if="item.title">{{ item.title }}</span>
                      </div>
                      <div class="hoverTittlePage" v-if="item.page">
                        <div
                          class="textName customTitlePage d-flex"
                          v-for="(ele, index) in item.page"
                          :key="index"
                        >
                          <div class="pageBorder">
                            <div v-if="ele.is_read" class="icon-check">
                              <b-icon icon="check"></b-icon>
                            </div>
                            <div v-else class="icon-check">
                              <img
                                class="icon-no-read"
                                :src="imgNoRead"
                                alt=""
                              />
                            </div>
                            <div style="padding-left: 30px">
                              {{ ele.page_title }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hoverTittlePage" v-else>
                        <div
                          class="textName customTitlePage d-flex"
                          style="padding-top: 0"
                        >
                          <div class="pageBorder">
                            <div v-if="item.is_read" class="icon-check">
                              <b-icon icon="check"></b-icon>
                            </div>
                            <div class="icon-check" v-else>
                              <img
                                class="icon-no-read"
                                :src="imgNoRead"
                                alt=""
                              />
                            </div>
                            <div style="padding-left: 0px">
                              {{ item.page_title }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="
                      index === 0
                        ? { background: '#fff' }
                        : { background: '#d1d1d1' }
                    "
                    class="line-catalogue-above"
                  ></div>
                  <div
                    :style="
                      index === dataContent.catalogues.length - 1
                        ? { background: '#fff' }
                        : { background: '#d1d1d1' }
                    "
                    class="line-catalogue-below"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="author-info w-50">
            <div class="label-info">
              <span class="titleBorder"></span>
              <span class="title">著者について</span>
            </div>
            <div class="d-flex author align-items-center">
              <div class="avatar-author">
                <img
                  :src="
                    dataContent.avatar
                      ? `${urlBackend}/${dataContent.avatar}`
                      : no_image
                  "
                />
              </div>
              <div class="">
                {{ dataContent.last_name + " " + dataContent.first_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="modal-confirm" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>カートに移動しますか？</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-success"
            block
            v-on:click.prevent="gotoCart()"
            >カートに移動する</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('modal-confirm')"
          >
            元のページに戻る
          </b-button>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="modal-vote" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>投票しました</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('modal-vote')"
          >
            買い物を続ける
          </b-button>
        </div>
      </b-modal>
    </div>
    <!-- modal create survey -->
    <div>
      <b-modal id="modal-create-survey" hide-header hide-footer>
        <div v-if="dataContent && dataContent.survey">
          <div class="d-block text-center">
            <h5>
              {{ dataContent.survey.survey_title }}
            </h5>
          </div>
          <p>{{ dataContent.survey.survey_description }}</p>
          <div v-for="(value, index) in dataContent.survey.column" :key="index">
            <CRow class="mb-3" v-if="dataContent.survey.column.length > 0">
              <CCol sm="12" v-if="value.type === 1">
                <label>{{ value.form.label }}</label>
                <div class="d-flex">
                  <b-form-input
                    class="w-100"
                    v-model="value.form.value"
                  ></b-form-input>
                </div>
              </CCol>
              <CCol sm="12" v-if="value.type === 2">
                <label>{{ value.form.label }}</label>
                <div class="d-flex">
                  <CTextarea
                    class="w-100"
                    v-model="value.form.value"
                    rows="10"
                  ></CTextarea>
                </div>
              </CCol>
              <CCol sm="12" v-if="value.type === 3">
                <label>{{ value.form.label }}</label>
                <div class="d-flex">
                  <b-form-select
                    v-model="value.form.value"
                    :options="value.form.options"
                  ></b-form-select>
                </div>
              </CCol>
              <CCol sm="12" v-if="value.type === 4">
                <label>{{ value.form.label }}</label>
                <div class="d-flex">
                  <b-form-radio-group
                    v-model="value.form.value"
                    :options="value.form.options"
                    :name="value.form.name"
                  ></b-form-radio-group>
                </div>
              </CCol>
              <CCol sm="12" v-if="value.type === 5">
                <label>{{ value.form.label }}</label>
                <div class="d-flex">
                  <b-form-checkbox-group
                    v-model="value.form.value"
                    :options="value.form.options"
                    :name="value.form.name"
                  ></b-form-checkbox-group>
                </div>
              </CCol>
            </CRow>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="mx-2 mt-3 btn btn-success"
              @click="createSurvey()"
              :disabled="loadingPaymentSurvey"
            >
              <b-spinner v-if="loadingPaymentSurvey" small></b-spinner>
              同意してアカウント作成</b-button
            >
            <b-button
              class="mx-2 mt-3 btn btn-secondary"
              @click="$bvModal.hide('modal-create-survey')"
            >
              キャンセル
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="modal-detail-pdf"
        hide-footer
        modal-class="modal-fullscreen"
        v-model="modalShow"
      >
        <ViewPdf
          v-bind:src="filePDFView && filePDFView.url"
          v-bind:idComponent="filePDFView && filePDFView.idComponent"
          v-bind:dataInfo="filePDFView"
        />
      </b-modal>
      <!-- <b-modal
        id="loading-page"
        hide-header
        hide-footer
        no-close-on-backdrop
        v-model="isLoadCheckout"
      >
        <div class="text-center" style="margin: 10px">
          <div class="position-relative w-100 d-flex justify-content-center">
            <div class="mikepad-loading">
              <div class="binding"></div>
              <div class="pad">
                <div class="line line1"></div>
                <div class="line line2"></div>
                <div class="line line3"></div>
              </div>
              <div class="text">ページ移動中</div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="loading-page"
        hide-header
        hide-footer
        no-close-on-backdrop
        v-model="isLoadCheckout2"
      >
        <div class="text-center" style="margin: 10px">
          <div class="position-relative w-100 d-flex justify-content-center">
            <div class="mikepad-loading">
              <div class="binding"></div>
              <div class="pad">
                <div class="line line1"></div>
                <div class="line line2"></div>
                <div class="line line3"></div>
              </div>
              <div class="text">支払いを処理中ですのでお待ちください</div>
            </div>
          </div>
        </div>
      </b-modal> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import image_url_default from "@/assets/img/content-default.png";
import no_image from "@/assets/img/mem.png";
import imgNoRead from "../../assets/img/icon_text.png";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common.js";
import moment from "moment";
// import ViewEditor from "../../components/viewEditor/index.vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";
import ViewPdf from "../../components/viewPdf/index.vue";

export default {
  name: "ContentDetail",
  data() {
    return {
      urlBackend: Constants.URL_BE,
      image_url_default,
      no_image,
      imgNoRead,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      id: this.$route.params.id,
      dataContent: null,
      dataRecmt: [],
      contentType: Constants.CONTENT_TYPE,
      isAllowPreSale: Constants.ALLOW_PRE_SALE,
      isAllowVote: Constants.IS_VOTE,
      keywordFilter: this.$route.query.keyword,
      isVote: false,
      isToken:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
      numDayPreSale: 0,
      hour: 0,
      minutes: 0,
      second: 0,
      hourdl: "",
      minutesdl: "",
      seconddl: "",
      loadingPaymentSurvey: false,
      interval: null,
      interval_date: null,
      isPreSale: false,
      checkShow: null,
      filePDFView: null,
      modalShow: false,
      // isLoadCheckout: false,
      // isLoadCheckout2: false,
    };
  },
  components: {
    // ViewEditor,
    YoutubePlayer,
    VimeoPlayer,
    ViewPdf,
  },
  async created() {
    // if (this.$route.query.session_id) {
    //   this.isLoadCheckout2 = true;
    //   await this.successBuyNow(this.$route.query.session_id);
    //   this.isLoadCheckout2 = false;
    //   window.close();
    // }
    const { shop_id, id } = this;
    // this.getDetailStore({ shopId: shop_id, id });
    await this.getDetailContentPayment({ shopId: shop_id, id });
    // const interval = setInterval(function () {
    //   if (document.getElementById("disable-right-click")) {
    //     document
    //       .getElementById("disable-right-click")
    //       .addEventListener("contextmenu", (event) => event.preventDefault());
    //     clearInterval(interval);
    //   }
    // }, 50);
    // document.addEventListener("contextmenu", (event) => event.preventDefault());

    let req = {
      shop_id: this.shop_id,
    };
    await this.checkSentence(req);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval_date);
  },
  watch: {
    async detailStore() {
      // if (this.detailStore[0].url_redirect) {
      //   window.location.href = this.detailStore[0].url_redirect;
      //   return;
      // }
      if (this.detailStore && this.detailStore.is_page404 == true) {
        this.checkShow = false;
        this.$router.push({
          name: this.$route.params.shopId ? "page404" : "page404 domain",
          params: {
            shopId: this.shop_id,
          },
        });
      } else this.checkShow = true;
      if (
        this.detailStore &&
        this.detailStore[0] &&
        this.detailStore[0].duplicate &&
        this.detailStore[0].duplicate == true
      ) {
        if (this.detailStore[0].url_redirect) {
          window.location.href = this.detailStore[0].url_redirect;
          return;
        } else {
          const infoContent = {
            id: this.detailStore[0].id,
            title: this.detailStore[0].content_title,
          };
          localStorage.setItem(
            Constants.INFO_CONTENT,
            JSON.stringify(infoContent)
          );
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "page notice",
              params: {
                shopId: this.shop_id,
              },
            });
          } else {
            this.$router.push({
              name: "page notice domain",
            });
          }
        }
      }
      // change sentence
      let baseDefault = this.$root.$refs.baseDefault;
      this.detailStore[0].content_title = baseDefault.changeSentence(
        this.detailStore[0].content_title
      );
      const self = this;
      if (
        this.detailStore[0].sale_offer.sale_method ===
          this.contentType.one_time_offer &&
        this.detailStore[0].time_read > 1
      ) {
        window.location.href =
          this.detailStore[0].sale_other.url_not_met_condition_oth;
        return;
      } else {
        this.dataContent = this.detailStore[0];
        if (
          this.detailStore[0].sale_advance.allow_pre_sale ===
          this.isAllowPreSale.allow
        ) {
          const numDayPreSale = this.getDatesInRange(
            new Date(),
            new Date(this.detailStore[0].sale_advance.period_setting_end_date)
          );
          this.numDayPreSale = numDayPreSale;
        }
      }
      if (
        this.detailStore[0].sale_basic &&
        parseInt(this.detailStore[0].sale_basic.column[0].priceOverTime) > 0 &&
        this.detailStore[0].sale_basic.column[0].fromTimeRelease <=
          moment(String(new Date())).format("YYYY-MM-DD HH:mm") &&
        this.detailStore[0].sale_basic.column[0].toTimeRelease >=
          moment(String(new Date())).format("YYYY-MM-DD HH:mm")
      ) {
        this.dataContent.sale_basic.column[0].product_price = parseInt(
          this.detailStore[0].sale_basic.column[0].priceOverTime
        );
      }
      if (
        this.detailStore[0].sale_offer.sale_method ===
        this.contentType.countdown
      ) {
        if (this.detailStore[0].sale_offer.countdown_type === 2) {
          if (this.detailStore[0].created_time_countdown) {
            this.getBetweenTwoDate(
              new Date(),
              new Date(this.detailStore[0].created_time_countdown),
              this.detailStore[0].sale_offer.countdown_time
            );
          } else {
            this.hour = this.detailStore[0].sale_offer.countdown_time;
          }
          this.interval = setInterval(function () {
            const countdown = self.countDown();
            if (countdown) {
              clearInterval(this.interval);
              window.location.href =
                self.detailStore[0].sale_other.url_not_met_condition_oth;
              return;
            }
          }, 1000);
        } else if (this.detailStore[0].sale_offer.countdown_type === 1) {
          this.interval_date = setInterval(function () {
            if (
              new Date() >=
              new Date(self.detailStore[0].sale_offer.countdown_date)
            ) {
              clearInterval(this.interval_date);
              window.location.href =
                self.detailStore[0].sale_other.url_not_met_condition_oth;
              return;
            }
          }, 1000);
        }
      }
      if (
        this.dataContent &&
        this.dataContent.sale_advance.allow_pre_sale ===
          this.isAllowPreSale.allow &&
        this.dataContent.sale_advance.period_setting_start_date <=
          moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss") &&
        this.dataContent.sale_advance.period_setting_end_date >=
          moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss")
      ) {
        this.isPreSale = true;
      }
      if (this.dataContent.catalogues) {
        this.dataContent.catalogues.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );
      }
      if (
        this.dataContent.sale_other.column &&
        this.dataContent.sale_other.column.length > 0
      ) {
        this.dataContent.sale_other.column =
          this.dataContent.sale_other.column.map((item) => {
            if (typeof item == "string") {
              return JSON.parse(item);
            } else {
              return item;
            }
          });

        this.dataContent.sale_other.column.forEach((fp) => {
          if (
            fp.text == "自由記入" &&
            fp.content &&
            fp.content.blocks &&
            fp.content.blocks.length > 0
          ) {
            if (Object.keys(this.detailCheckSentence).length > 0) {
              Object.keys(this.detailCheckSentence).forEach((key) => {
                var value = this.detailCheckSentence[key];
                const regex = new RegExp("{%" + key + "%}", "g"); // add this regex
                // eslint-disable-next-line no-unused-vars
                fp.content.blocks.forEach((item) => {
                  if (item.data && item.data.text) {
                    item.data.text = item.data.text.replace(regex, value);
                  }

                  if (
                    item.data &&
                    item.data.content &&
                    item.data.content.length > 0
                  ) {
                    item.data.content.forEach((itemContent) => {
                      if (itemContent.length > 0) {
                        itemContent.forEach((it, index) => {
                          itemContent[index] = it.replace(regex, value);
                        });
                      }
                    });
                  }
                });
              });
            }
          }
        });
      }
    },
    success() {
      if (this.success) {
        if (!this.isBuyNow && !this.isVote) {
          this.$toasted.success(this.message);
        } else if (this.isBuyNow) {
          this.$router.push({
            name: this.$route.params.shopId
              ? "default cart"
              : "default cart domain",
            params: { shopId: this.shop_id },
          });
        } else if (this.isVote) {
          this.$toasted.success(this.message);
          const { shop_id, id } = this;
          this.getDetailStore({ shopId: shop_id, id });
          this.$bvModal.show("modal-vote");
          this.isVote = false;
        }
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.getListCart({
          shop_id: this.shop_id,
        });
      }
    },
    error() {
      if (this.error) {
        if (!this.isBuyNow) {
          this.$toasted.error(this.message);
        } else {
          this.$router.push({
            name: this.$route.params.shopId
              ? "default cart"
              : "default cart domain",
            params: { shopId: this.shop_id },
          });
        }
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  computed: {
    ...mapGetters({
      detailStore: "detailStore",
      success: "success",
      error: "error",
      message: "message",
      detailCheckSentence: "detailCheckSentence",
    }),
  },
  mounted() {
    document.addEventListener("click", async (e) => {
      const origin = e.target.closest("a");
      if (origin) {
        console.clear();
        let href = origin.href;
        if (href) {
          if (href.includes("/fixed-page/")) {
            let splitHref = href.split("/");
            let splitHrefRevert = this.objectFlip(splitHref);
            if (splitHrefRevert["fixed-page"]) {
              let id = splitHref[Number(splitHrefRevert["fixed-page"]) + 1];
              const request = {
                id: id,
                shop_id: this.shop_id,
              };
              e.preventDefault();
              e.stopPropagation();
              let resp = await this.$store.dispatch(
                "getFixedPageUser",
                request
              );
              if (resp.data.type == 8) {
                this.$store.commit("set", ["idModalFixed", resp.data.id]);
              } else {
                if (origin.target !== "_blank") {
                  window.location.href = href;
                } else {
                  window.open(href, "_blank");
                }
              }
            }
          }
        }
      }
    });

    // window.addEventListener("storage", this.handleStorageEvent);
  },
  methods: {
    ...mapActions({
      getDetailContentPayment: "getDetailContentPayment",
      addToCart: "addToCart",
      getListCart: "getListCart",
      voteContent: "voteContent",
      createAnswerSurvey: "createAnswerSurvey",
      checkSentence: "checkSentence",
    }),
    // handleStorageEvent(event) {
    //   if (event.key === "checkout") {
    //     let checkoutJson = JSON.parse(event.newValue);
    //     if (checkoutJson.status == "success") {
    //       this.$toasted.success(checkoutJson.message);
    //     } else {
    //       this.$toasted.error(checkoutJson.message);
    //     }
    //     this.isLoadCheckout = false;
    //     if (checkoutJson.data.url_page_thank) {
    //       if (checkoutJson.data.url_page_thank.includes("fixed-page")) {
    //         localStorage.setItem(
    //           Constants.INFOR_ORDER,
    //           JSON.stringify(checkoutJson.data)
    //         );
    //       }
    //       window.location.href = checkoutJson.data.url_page_thank;
    //     } else {
    //       this.$router.push({
    //         name: this.$route.params.shopId
    //           ? "page_thank"
    //           : "page_thank domain",
    //         params: { shopId: this.shopId },
    //       });
    //     }
    //   }
    // },
    objectFlip(obj) {
      return Object.keys(obj).reduce((ret, key) => {
        ret[obj[key]] = key;
        return ret;
      }, {});
    },
    moment,
    formatNumber(value) {
      return Number(value).toLocaleString("ja");
    },
    addCart(id) {
      if (this.isToken) {
        this.$bvModal.show("modal-confirm");
        const formdata = {
          content_id: [id.toString()],
          shop_id: this.shop_id,
          title: this.$route.meta.title,
          referrer_url: window.location.href,
          url: window.document.activeElement.baseURI,
          id_button: "add_content_" + id,
          add_cart_url:
            window.location.origin +
            this.$router.resolve({
              name: this.$route.params.shopId
                ? "content detail"
                : "content detail domain",
              params: {
                id: id,
              },
              query: { keyword: "" },
            }).href,
        };
        this.addToCart(formdata);
      } else {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shopId: this.shop_id },
        });
      }
    },
    // async checkOrder(id, title) {
    //   const dataReturn = await this.checkOrderDuplicate({
    //     shop_id: this.shop_id,
    //     id: id,
    //   });
    //   if (dataReturn.success) {
    //     let urlDuplicate =
    //       dataReturn.data["sale_basic"]["column"][0]["urlDuplicate"];
    //     if (urlDuplicate) {
    //       window.location.href = urlDuplicate;
    //     } else {
    //       const infoContent = {
    //         id: id,
    //         title: title,
    //       };
    //       localStorage.setItem(
    //         Constants.INFO_CONTENT,
    //         JSON.stringify(infoContent)
    //       );
    //       const hostName = window.location.origin;
    //       const href = this.$router.resolve({
    //         name: this.$route.params.shopId
    //           ? "page notice"
    //           : "page notice domain",
    //         params: {
    //           shopId: this.shopId,
    //         },
    //       }).href;
    //       window.location.href = hostName + href;
    //     }
    //   }
    //   return false;
    // },
    async buyNow(id) {
      // await this.checkOrder(id, this.dataContent.content_title);
      // localStorage.setItem("checkout", "");
      // const req = {
      //   content_id: id,
      //   shop_id: this.shop_id,
      // };
      // await Api.userRequestServer
      //   .post(`/stripe/check-buy-now`, req)
      //   .then((response) => {
      //     const { data } = response;
      //     if (data.success) {
      //       if (data.data.type == 0) {
      //         var popupWinWidth = 1000;
      //         var popupWinHeight = 800;
      //         var left = (screen.width - popupWinWidth) / 2;
      //         var top = (screen.height - popupWinHeight) / 4;

      //         window.open(
      //           data.data.url,
      //           "targetWindow",
      //           `toolbar=no,
      //           location=no,
      //           status=no,
      //           menubar=no,
      //           scrollbars=yes,
      //           resizable=yes,
      //           width=` +
      //             popupWinWidth +
      //             `,
      //           height=` +
      //             popupWinHeight +
      //             `,
      //           top=` +
      //             top +
      //             `,
      //           left=` +
      //             left +
      //             `);
      //           return false;"`
      //         );
      //         this.isLoadCheckout = true;
      //       }
      //     }
      //     return data;
      //   })
      //   .catch((error) => {
      //     this.$store.commit("set", ["error", true]);
      //     this.$store.commit("set", ["message", error.message]);
      //     return { success: false };
      //   });
      this.$router.push({
        name: this.$route.params.shopId ? "payment" : "payment domain",
        params: { id: id },
      });
    },
    // async successBuyNow(session_id) {
    //   const req = {
    //     session_id: session_id,
    //     content_id: this.$route.params.id,
    //     shop_id: this.shopId,
    //   };
    //   await Api.userRequestServer
    //     .post(`/stripe/buy-now`, req)
    //     .then((response) => {
    //       const { data } = response;
    //       localStorage.setItem(
    //         "checkout",
    //         JSON.stringify({
    //           message: data.message,
    //           status: data.success ? "success" : "error",
    //           data: data.data,
    //         })
    //       );
    //       return data;
    //     })
    //     .catch((error) => {
    //       localStorage.setItem(
    //         "checkout",
    //         JSON.stringify({
    //           message: error.message,
    //           status: "error",
    //         })
    //       );
    //     });
    // },
    vote(id) {
      if (this.isToken) {
        const request = {
          shop_id: this.shop_id,
          status: 1,
          content_id: id,
        };
        this.isVote = true;
        this.voteContent(request);
      } else {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shopId: this.shop_id },
        });
      }
    },
    gotoStore() {
      this.$router.push({
        name: this.$route.params.shopId ? "store" : "store domain",
        params: {
          shopId: this.shop_id,
        },
      });
    },
    gotoCart() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "default cart"
          : "default cart domain",
        params: {
          // shopId: this.shop_id,
        },
      });
    },
    showSurvey() {
      this.$bvModal.show("modal-create-survey");
    },
    getDatesInRange(startDate, endDate) {
      let oned = 24 * 60 * 60 * 1000;
      return Math.ceil((endDate - startDate) / oned);
    },
    countDown() {
      this.hour =
        this.minutes === 0 && this.second === 0 ? this.hour - 1 : this.hour;
      this.minutes = this.second === 0 ? this.minutes - 1 : this.minutes;
      this.second = this.second - 1;
      if (this.minutes < 0) {
        this.minutes = 59;
      }
      if (this.second < 0) {
        this.second = 59;
      }
      this.hourdl = this.hour < 10 ? "0" + this.hour : this.hour;
      this.minutesdl = this.minutes < 10 ? "0" + this.minutes : this.minutes;
      this.seconddl = this.second < 10 ? "0" + this.second : this.second;

      if (this.hour < 0) {
        return true;
      } else {
        return false;
      }
    },
    getBetweenTwoDate(date_now, date_future, hour_begin) {
      // get total seconds between the times
      var delta = Math.abs(date_future - date_now) / 1000;

      // calculate (and subtract) whole days
      // var days = Math.floor(delta / 86400);
      // delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hour = Math.floor(delta / 3600) % 24;
      delta -= hour * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var second = delta % 60;

      this.hour = hour_begin - hour - 1;
      this.minutes = Math.floor(59 - minutes);
      this.second = Math.floor(59 - second);
    },
    createSurvey() {
      let error;
      const { survey } = this.dataContent;
      const reg = {
        survey_id: survey.id,
        shop_id: this.shop_id,
        column: survey.column,
      };
      if (survey.column.length > 0) {
        survey.column.forEach((item) => {
          if (!item.form.value || item.form.value.length === 0) {
            error = true;
          }
        });
      }
      if (error) {
        this.$toasted.error("質問を全部回答していただければ助かります。");
        return;
      }
      this.loadingPaymentSurvey = true;
      this.createAnswerSurvey(reg);
      this.buyNoPrice();
    },
    buyNoPrice() {
      this.loadingPaymentSurvey = true;
      const { id, pre_sale_amount, discount, pointUse, point_bonus } =
        this.dataContent;
      const orders = {
        order: {
          shop_id: this.shop_id,
          payment_type: 5,
          status_order: 2,
          total_amount: 0,
          total_payment_amount: 0,
          total_point_use: 0,
          total_point_received: point_bonus.total_point_bonus,
        },
        order_details: [
          {
            payment_method: 0,
            content_id: id,
            price: 0,
            payment_amount: pre_sale_amount,
            discount: discount ? discount : 0,
            point_use: pointUse ? pointUse : 0,
            point_received: point_bonus.total_point_bonus,
          },
        ],
      };
      const url = `/suvery`;
      Api.userRequestServer
        .post(url, orders)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.$toasted.success(data.message);
            this.getListCart({
              shop_id: this.shop_id,
            });
            this.loadingPaymentSurvey = false;
            this.$router.push({
              name: this.$route.params.shopId ? "library" : "library domain",
              params: { shopId: this.shop_id },
            });
          } else {
            this.$toasted.error(data.message);
            this.loadingPaymentSurvey = false;
          }
        })
        .catch((error) => {
          this.loadingPaymentSurvey = false;
          if (error.response.status == 500) {
            this.$toasted.error(error.message);
          }
        });
    },

    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
    replaceText(str) {
      return str ? str.replaceAll("&nbsp;", "").trim() : str;
    },
    checkIndex(toggle) {
      var listToggle = this.content.blocks.filter(
        (item) => item.idChild === toggle.idChild
      );
      if (listToggle.length == 1) {
        return true;
      } else {
        var indexCurrent = listToggle.findIndex(
          (item) => item.id === toggle.id
        );
        if (indexCurrent == listToggle.length - 1) return true;
        else return false;
      }
    },
    convertSize(size) {
      if (size) {
        let sizePrefix;
        let formattedSize;

        if (Math.log10(+size) >= 6) {
          sizePrefix = "MB";
          formattedSize = size / Math.pow(2, 20);
        } else {
          sizePrefix = "KB";
          formattedSize = size / Math.pow(2, 10);
        }

        return formattedSize.toFixed(1) + sizePrefix;
      }
    },
    async downloadFile(event, data) {
      const fileType = [
        "doc",
        "docx",
        "odt",
        "pdf",
        "rtf",
        "tex",
        "txt",
        "pptx",
        "ppt",
        "mp3",
        "mp4",
        "xls",
        "html",
        "htm",
        "png",
        "jpg",
        "jpeg",
        "gif",
        "zip",
        "rar",
        "exe",
        "svg",
        "key",
        "sketch",
        "ai",
        "psd",
        "dmg",
        "json",
        "csv",
      ];
      const url = data.link;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      const typeFile = url.substring(url.lastIndexOf(".") + 1);
      const checkFile = fileType.filter((item) => item === typeFile);
      if (checkFile.length > 0) {
        fetch(url, {
          header: "Access-Control-Allow-Origin: *",
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Use the blob here...
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
          });
      } else {
        if (data.checkbox) {
          window.open(url, "_blank");
          return;
        }
        window.location = url;
      }
    },
    async downloadFileUpload(event, data) {
      const url = data.url;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      fetch(url, {
        header: "Access-Control-Allow-Origin: *",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Use the blob here...
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
        });
    },
    async viewFilePDF(file) {
      this.$store.commit("set", ["clickOnPDF", true]);
      if (file.view === null) {
        const req = {
          shop_id: localStorage.getItem(Constants.SHOP_ID),
          url: window.document.activeElement.href
            ? window.document.activeElement.href
            : window.document.activeElement.baseURI,
          pdf_url: file.url,
        };
        const dataReturn = await this.$store.dispatch("countViewPDF", req);
        if (dataReturn.success) {
          file.view = dataReturn.data + 1;
        }
      } else {
        file.view = file.view + 1;
      }
      this.filePDFView = file;
      this.modalShow = !this.modalShow;
    },
  },
};
</script>
<style lang="scss">
.ct-detail {
  &-img {
    width: 100%;
    height: 230px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-btn {
    button {
      width: 300px;
      max-width: 100%;
      height: 40px;
    }
  }
}
.label-vote {
  width: 300px;
  max-width: 100%;
  height: 40px;
  color: #4f5d73;
  background-color: #ced2d8;
  border-color: #ced2d8;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.router-store:hover {
  cursor: pointer;
}
//.detail-content {
//  user-select: none; /* supported by Chrome and Opera */
//  -webkit-user-select: none; /* Safari */
//  -khtml-user-select: none; /* Konqueror HTML */
//  -moz-user-select: none; /* Firefox */
//  -ms-user-select: none; /* Internet Explorer/Edge */
//}
.dc-header {
  padding-top: 20px;
  font-size: 18px;
  a {
    color: #000;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }
}
.title-content {
  background: #000;
  color: #fff;
  padding: 10px 10px;
  margin-bottom: 30px;
  .image-content {
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
  .title-no-img {
    background: #0376bb;
    color: #fff;
    // fix show title content center
    display: grid !important;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .description-content {
    margin-left: 10px;
    .title {
      font-size: 32px;
      font-weight: bold;
    }
    .author {
      margin-top: 10px;
      .avatar-author {
        margin-right: 10px;
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
}
.author-info {
  margin-bottom: 30px;
  .author {
    padding-left: 20px;
    margin-top: 10px;
    font-weight: bold;
    .avatar-author {
      margin-right: 10px;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}
.catalogue-info {
  @media (max-width: 450px) {
    width: 100% !important;
  }
  .catalogue {
    .catalogue-list {
      width: 450px;
      @media (max-width: 450px) {
        width: 100%;
      }
      .contents-list-item {
        padding: 15px 0;
        .icon-check {
          height: 25px;
          width: 25px;
          background: green;
          color: white;
          position: relative;
          border-radius: 50%;
          z-index: 4;
          img {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 4;
          }
        }
        .content-catalogue {
          div {
            .customTitleChapter {
              width: 380px;
              @media (max-width: 450px) {
                width: auto;
              }
              height: 65px;
              display: block;
              text-decoration: none;
              font-size: 16px;
              padding: 20px 15px;
              font-weight: normal;
              border-top: 1px solid #e6e6e6;
              display: block;
              line-height: 24px;
              text-align: left;
              height: auto;
            }
            .hoverTittlePage {
              pointer-events: none;
              .textName {
                font-size: 17px;
                font-weight: 500;
              }
              .customTitlePage {
                height: 65px;
                display: block;
                text-decoration: none;
                font-size: 16px;
                padding: 20px 15px;
                font-weight: normal;
              }
              .customTitlePage::before {
                content: "";
                display: block;
                width: 2px;
                height: 100%;
                position: absolute;
                background-color: #d1d1d1;
                left: 34px;
                top: 0;
                z-index: 3;
              }
            }
          }
        }
        .line-catalogue-above {
          display: none;
          width: 2px;
          height: 100%;
          position: absolute;
          left: 34px;
          top: 0;
          z-index: 3;
        }
        .line-catalogue-below {
          display: none;
          width: 2px;
          height: 100%;
          position: absolute;
          left: 34px;
          top: 50%;
          z-index: 3;
        }
      }
    }
  }
}
.noBorderTop {
  border-top: none !important;
}
.label-info {
  margin-bottom: 10px;
  .titleBorder {
    display: inline-block;
    background-color: #f55747;
    height: 18px;
    width: 5px;
    vertical-align: middle;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    vertical-align: middle;
  }
}
.price-list {
  @media (min-width: 992px) {
    float: right;
  }
  text-align: center;
  .title-price-list {
    font-weight: bold;
    font-size: 24px;
  }
  .title-many-price {
    color: red;
    font-weight: bold;
  }
  .info-price {
    margin-top: 10px;
    padding: 20px 30px;
    border: 1px solid red;
    .plan-name {
      margin-bottom: 5px;
    }
    .plan-price {
      margin-bottom: 5px;
      font-size: 24px;
    }
    .text-button {
      margin-bottom: 5px;
      button {
        background: #ed5144;
        color: #fff;
        padding: 5px 10px;
        border: none;
        min-width: 200px;
      }
    }
    .add-cart {
      margin-bottom: 5px;
      button {
        background: #33b033;
        color: #fff;
        padding: 5px 10px;
        border: none;
        min-width: 200px;
      }
    }
  }
}
.page-content-detail {
  .customTitlePage {
    height: 65px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    padding: 20px 15px;
    font-weight: normal;
  }
  .customTitlePage::before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    background-color: #d1d1d1;
    left: 7.7%;
    top: 0;
  }
  .page-chaper-0 {
    pointer-events: none;
    .pageBorder {
      border-top: 1px solid #e6e6e6 !important;
    }
  }
}
.version-pc {
  .cus-popup-price {
    position: fixed;
    top: 40%;
    right: 23%;
    background: white;
    padding: 0px;
    margin: 0px;
    z-index: 99;
    .info-price {
      margin: 0px;
    }
  }
}
.is-header {
  background: black;
}
.mt40 {
  margin-top: 40px;
}
</style>

<!-- @TODO 今後、影響範囲を狭めるためscoped内にscssを記載すること -->
<style lang="scss" scoped>
.item-data {
  img {
    max-width: 100%;
  }
}
.content-cart {
  max-width: 450px;
  width: 90%;
  box-shadow: 0px 3px 6px #7681929c;
  height: auto;
  border-radius: 10px;
  padding: 20px 0;
  text-align: center;
  position: fixed;
  bottom: 20px;
  z-index: 1000;
  background: #fff;
  min-width: 310px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
  .title-price {
    font-size: 24px;
  }
  .title-text-price {
    font-size: 18px;
  }
  button {
    background-color: #68c684;
    color: #fff !important;
    background-color: rgb(46, 184, 92) !important;
    border-color: rgb(46, 184, 92) !important;
  }
}
@media screen and (max-width: 450px) {
  .content-cart {
    bottom: 80px;
  }
}
</style>
<style lang="scss" scoped>
.title-content-wrapper {
  font-family: $fontFamilyBase;
}
.accordion-page {
  font-size: $fontSizeBase;
  line-height: $lineHeightBase;
}
</style>
